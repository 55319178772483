import React, { useState, useEffect, useRef } from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {Alert, Button, Col, Container, Form, Row, Modal} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";
import babyPortraitsStyle from "./baby-portraits.module.css";
import env from "../config/env";
import HolidaysPortraitCarousel from "../components/holidays-portrait-carousel";
import ClassicPortraitCarousel from "../components/classic-portrait-carousel";
import formStyle from "./form.module.css";
// import addToMailchimp from "gatsby-plugin-mailchimp";
import addToMailchimp from "../services/mailchimp";
import axios from "axios";
import Img from "gatsby-image";
import Carousel from "react-multi-carousel";

let stripe;
if (typeof window !== "undefined") {
  stripe = window.Stripe(env.GATSBY_STRIPE_PUBLIC_KEY);
}

const BabyPortraitsPage = ({data, location}) => {
  const [finalPrice, setFinalPrice] = useState(0);
  const [currentSku, setCurrentSku] = useState('');
  const [showNameErrorMessage, setShowNameErrorMessage] = useState({ show: false, message: "" });
  const [showBabyNameErrorMessage, setShowBabyNameErrorMessage] = useState({ show: false, message: "" });
  const [showDeliveryTypeErrorMessage, setShowDeliveryTypeErrorMessage] = useState({ show: false, message: "" });
  const [showPortretTypeErrorMessage, setShowPortretTypeErrorMessage] = useState({ show: false, message: "" });
  const [selectedHolidayStyleImg, setSelectedHolidayStyleImg] = useState('valentine-day-style1');
  const [selectedClassicBackdropImg, setSelectedClassicBackdropImg] = useState('midnight1');
  const [backdropColSize, setBackdropColSize] = useState(4);
  const [babySex, selectBabySex] = useState('girl');
  const [showPhotoErrorMessage, setShowPhotoErrorMessage] = useState({ show: false, message: "" });
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState({ show: false, message: "" });
  const [showAddressErrorMessage, setShowAddressErrorMessage] = useState({ show: false, message: "" });
  const [showPhoneErrorMessage, setShowPhoneErrorMessage] = useState({ show: false, message: "" });
  const [modalShow, setModalShow] = useState(false);

  const [showContactFirstNameErrorMessage, setShowContactFirstNameErrorMessage] = useState({ show: false, message: "" });
  const [showContactLastNameErrorMessage, setShowContactLastNameErrorMessage] = useState({ show: false, message: "" });
  const [showContactEmailErrorMessage, setShowContactEmailErrorMessage] = useState({ show: false, message: "" });
  const [showContactPhoneErrorMessage, setShowContactPhoneErrorMessage] = useState({ show: false, message: "" });
  const [showContactMessageErrorMessage, setShowContactMessageErrorMessage] = useState({ show: false, message: "" });
  const submitContactUsUrl = 'https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=a895272a15';

  const queryParams = new URLSearchParams(location.search)
  let defaultPortraitType = 'Holiday';
  if (queryParams.get("type") === 'classic') {
    defaultPortraitType = 'Classic'
  }
  const [formState, setFormValues] = useState({
    portraitType: defaultPortraitType,
    classicBackdropStyle: "Midnight",
    nurseryStyle: "",
    holidayStyle: "Valentine Day",
    babyName: "",
    deliveryType: "Digital Delivery Only",
    frameColor: "Black",
    name: "",
    email: "",
    discountCode: "",
    photoB64: "",
    photoName: "",
    noOfCopies: 1,
    path: "/baby-portraits",
    contactFirstName: "",
    contactLastName: "",
    contactEmail: "",
    contactPhone: "",
    contactMessage: ""
  });

  const [contactFormState, setContactFormValues] = useState({
    contactFirstName: "",
    contactLastName: "",
    contactEmail: "",
    contactPhone: "",
    contactMessage: ""
  });

  const defaultStyles = {
    nurseryStyle: "",
    holidayStyle: "",
  };

  const defaultPrices = {
    digitalDelivery: 20,
    digitalDeliveryAndPrint: 40,
    digitalDeliveryAndPrintAndFrame: 70,
  };

  useEffect(() => {
    changeHolidaySlide();
    changeClassicSlide();
  }, [babySex]);

  const holidayStyles = [{
      id: 'valentine-day-style',
      title: 'Valentine Day',
      images: [
        'valentine-day-style1',
        'valentine-day-style2',
      ],
    }, {
      id: 'easter-style',
      title: 'Easter',
      images: [
        'easter-style1',
        'easter-style2',
      ],
    }, {
      id: 'mothers-day-style',
      title: 'Mothers Day',
      images: [
        'mothers-day-style1',
        'mothers-day-style2',
      ],
    }, {
      id: 'fathers-day-style',
      title: 'Fathers Day',
      images: [
        'fathers-day-style1',
        'fathers-day-style2',
      ],
    }, {
      id: 'red-winter-themed-style',
      title: 'Winter - Red',
      images: [
        'red-winter-themed-style1',
        'red-winter-themed-style2',
      ],
    }, {
      id: 'blue-winter-themed-style',
      title: 'Winter - Blue',
      images: [
        'blue-winter-themed-style1',
        'blue-winter-themed-style2',
      ],
    }
  ];

  const changeHolidaySlide = (holidayStyle) => {
    if (!holidayStyle) {
      holidayStyle = holidayStyles.find(item => item.title === formState.holidayStyle);
    }
    if (holidayStyle) {
      const image = holidayStyle.images[babySex === 'girl' ? 0 : 1]
      setSelectedHolidayStyleImg(image);
      updateCarouselImage(image);
    }
  }

  const classicBackdrops = [
    {
      id: 'Midnight',
      title: 'Midnight',
      images: [
        'midnight1',
        'midnight2',
      ],
      cssClass: babyPortraitsStyle.midnightColor,
    },
    {
      id: 'Coconut',
      title: 'Coconut',
      images: [
        'coconut1',
        'coconut2',
      ],
      cssClass: babyPortraitsStyle.coconutColor,
    },
    {
      id: 'Rose',
      title: 'Rose',
      images: [
        'rose1',
        'rose2',
      ],
      cssClass: babyPortraitsStyle.roseColor,
    },
    {
      id: 'Lime',
      title: 'Lime',
      images: [
        'lime1',
        'lime2',
      ],
      cssClass: babyPortraitsStyle.limeColor,
    },
    {
      id: 'Blue',
      title: 'Blue',
      images: [
        'blue1',
        'blue2',
      ],
      cssClass: babyPortraitsStyle.blueClassicColor,
    }
  ];

  const changeClassicSlide = (classicStyle) => {
    if (!classicStyle) {
      classicStyle = classicBackdrops.find(item => item.title === formState.classicBackdropStyle);
    }
    if (classicStyle) {
      const image = classicStyle.images[babySex === 'girl' ? 0 : 1]
      setSelectedClassicBackdropImg(image);
      updateClassicCarouselImage(image);
    }
  }

  const chooseHoliday = () => {
    setFormValues({...formState, ...defaultStyles, portraitType: "Holiday", holidayStyle: holidayStyles[0].title});
    changeHolidaySlide(holidayStyles[0]);
  };

  const chooseClassic = () => {
    setFormValues({...formState, ...defaultStyles, portraitType: "Classic", classicBackdropStyle: classicBackdrops[0].title});
    setBackdropColSize(4);
    changeClassicSlide(classicBackdrops[0]);
  };

  const handleRegisterForPhotosFormChange = e => {
    if (e.target.name === "photo") {
      handleFile(e);
    } else {
      let value = e.target.value;
      if (e.target.name === "noOfCopies") {
        value = parseInt(e.target.value);
      }
      setFormValues({
        ...formState,
        [e.target.name]: value,
      });
    }

    if (e.target.name === "deliveryType" || e.target.name === "noOfCopies") {
      updateFinalPrice({[e.target.name]: e.target.value});
    }
  };

  const chooseDigitalDeliveryOnly = (e) => {
    setFormValues({...formState, noOfCopies: 1, deliveryType: e.target.value});
    updateFinalPrice({deliveryType: e.target.value, noOfCopies: 1});
  };

  const handleFile = (formChangeEvent) => {
    const file = formChangeEvent.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      setFormValues({
        ...formState,
        photoName: file.name,
        photoB64: btoa(readerEvent.target.result),
      });
    };
    reader.readAsBinaryString(file);
  };

  const skus = {
    digitalDelivery: 'sku_IVPU8oXMXVCbKb',
    digitalDeliveryAndPrint: 'sku_IVPZFOxF9PLDLL',
    digitalDeliveryAndPrint10Off: 'sku_IVQcwsTWjfMLIv',
    digitalDeliveryAndPrint20Off: 'sku_IVQZg6ybfEUk5y',
    digitalDeliveryAndPrintAndFrame: 'sku_IVPaPxsiplNVdu',
    digitalDeliveryAndPrintAndFrame10Off: 'sku_IVQe05XbmHnUOS',
    digitalDeliveryAndPrintAndFrame20Off: 'sku_IVQfvHiOa69U7g',
  };
  // TEST SKUS. Uncomment the following code when you want to use Stripe test data
  // const skus = {
  //   digitalDelivery: 'sku_IVdUu8r2GIUEc5',
  //   digitalDeliveryAndPrint: 'sku_IVdVLbJ1YioDrt',
  //   digitalDeliveryAndPrint10Off: 'sku_IVdX7lDlt9nFPv',
  //   digitalDeliveryAndPrint20Off: 'sku_IVdWXZaFP3p96t',
  //   digitalDeliveryAndPrintAndFrame: 'sku_IVdV4VtCdTlT3j',
  //   digitalDeliveryAndPrintAndFrame10Off: 'sku_IVdXArxLcKBTtn',
  //   digitalDeliveryAndPrintAndFrame20Off: 'sku_IVdY6fetxsw0Vh',
  // };

  const updateCurrentSku = (deliveryType, noOfCopies) => {
    noOfCopies = parseInt(noOfCopies);
    let sku = '';
    if (deliveryType === 'Digital Delivery Only') {
      sku = skus.digitalDelivery;
    } else if (deliveryType === 'Digital Delivery and Print and Ship a 5x7 and 8x10') {
      if (noOfCopies === 1) {
        sku = skus.digitalDeliveryAndPrint;
      } else if (noOfCopies === 2) {
        sku = skus.digitalDeliveryAndPrint10Off;
      } else {
        sku = skus.digitalDeliveryAndPrint20Off;
      }
    } else if (deliveryType === 'Digital Delivery and Print, Frame and Ship Finished Art') {
      if (noOfCopies === 1) {
        sku = skus.digitalDeliveryAndPrintAndFrame;
      } else if (noOfCopies === 2) {
        sku = skus.digitalDeliveryAndPrintAndFrame10Off;
      } else {
        sku = skus.digitalDeliveryAndPrintAndFrame20Off;
      }
    }
    setCurrentSku(sku);
  };

  const updateFinalPrice = (data) => {
    const deliveryType = data['deliveryType'] || formState.deliveryType;
    const noOfCopies = data['noOfCopies'] || formState.noOfCopies;
    let price = 0;
    let discountPercent = 0;
    let shouldCheckForDiscount = false;

    if (deliveryType === 'Digital Delivery Only') {
      price = defaultPrices.digitalDelivery;
    } else if (deliveryType === 'Digital Delivery and Print and Ship a 5x7 and 8x10') {
      price = defaultPrices.digitalDeliveryAndPrint;
      shouldCheckForDiscount = true;
    } else if (deliveryType === 'Digital Delivery and Print, Frame and Ship Finished Art') {
      price = defaultPrices.digitalDeliveryAndPrintAndFrame;
      shouldCheckForDiscount = true;
    }

    if (shouldCheckForDiscount) {
      discountPercent = noOfCopies === 2 ? 10 : (noOfCopies >= 3 ? 20 : 0);
    }

    price *= noOfCopies;
    const finalPrice = price - price * discountPercent / 100;
    setFinalPrice(finalPrice);

    updateCurrentSku(deliveryType, noOfCopies);
  };

  const validateForm = () => {
    let isValid = true;

    if (!formState.portraitType) {
      isValid = false;
      setShowPortretTypeErrorMessage({
        show: true,
        message: "Please choose a portrait style!"
      })
    }
    if (!formState.deliveryType) {
      isValid = false;
      setShowDeliveryTypeErrorMessage({
        show: true,
        message: "Please choose how you want it delivered!"
      })
    }
    if (!formState.photoB64) {
      isValid = false;
      setShowPhotoErrorMessage({
        show: true,
        message: "Please upload a photo of your child!"
      })
    }

    return isValid
  };

  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => {
    let isValid = validateForm();
    if(isValid) {
      setModalShow(true);
    }
  }

  const validateModalForm = () => {
    let isValid = true;

    if (!formState.portraitType) {
      isValid = false;
      setShowPortretTypeErrorMessage({
        show: true,
        message: "Please choose a portrait style!"
      })
    }
    if (!formState.deliveryType) {
      isValid = false;
      setShowDeliveryTypeErrorMessage({
        show: true,
        message: "Please choose how you want it delivered!"
      })
    }
    if (!formState.name) {
      isValid = false;
      setShowNameErrorMessage({
        show: true,
        message: "Please enter your name!"
      })
    }
    if (!formState.email) {
      isValid = false;
      setShowEmailErrorMessage({
        show: true,
        message: "Please enter your email!"
      })
    }
    if (!formState.photoB64) {
      isValid = false;
      setShowPhotoErrorMessage({
        show: true,
        message: "Please upload a photo of your child!"
      })
    }
    if (!formState.phone) {
      isValid = false;
      setShowPhoneErrorMessage({
        show: true,
        message: "Please enter your phone no!"
      })
    }
    if (!formState.address) {
      isValid = false;
      setShowAddressErrorMessage({
        show: true,
        message: "Please enter your address!"
      })
    }
    if (!formState.babyName) {
      isValid = false;
      setShowBabyNameErrorMessage({
        show: true,
        message: "Please enter your baby name!"
      })
    }

    return isValid
  };

  const saveToMailchimp = async () => {
    try {
      await addToMailchimp(formState.email, {
        ADDRESS: formState.address,
        BABYNAME: formState.babyName,
        CLASSIC: formState.classicBackdropStyle,
        DLVRTYPE: formState.deliveryType,
        FRAMECOLOR: formState.frameColor,
        HOLIDAY: formState.holidayStyle,
        NAME: formState.name,
        NURSERY: formState.nurseryStyle,
        PHONE: formState.phone,
        PORTRAIT: formState.portraitType,
        }, env.submitPhotosUrl
      );
    } catch (err) {
      console.error(err);
    }
    return true;
  };

  const savePhotoToS3 = async () => {
    return axios({
      method: 'post',
      url: env.uploadPhotoUrl,
      data: {
        email: formState.email,
        base64Photo: formState.photoB64,
      },
      headers: {
        'Access-Control-Allow-Origin': window.location.origin,
      },
    });
  };

  const redirectToStripe = async () => {
    return await stripe.redirectToCheckout({
      items: [{
        sku: currentSku,
        quantity: formState.noOfCopies,
      }],
      customerEmail: formState.email,
      successUrl: env.STRIPE_PHOTOS_PORTRAIT_SUCCESS_URL,
      cancelUrl: window ? window.location.protocol + "//" + window.location.host + formState.path : "",
      billingAddressCollection: "required",
      // shippingAddressCollection: shipping,
    });
  };

  const validateAndSaveData = async () => {
    if (validateModalForm()) {
      await saveToMailchimp();
      await savePhotoToS3();
      await redirectToStripe();
    }
  };

  useEffect(() => {
    updateFinalPrice({deliveryType: formState.deliveryType, noOfCopies: formState.noOfCopies});
  });

  const validateContactForm = () => {
    let isValid = true;

    if (!contactFormState.contactFirstName) {
      isValid = false;
      setShowContactFirstNameErrorMessage({
        show: true,
        message: "Please type your first name!"
      })
    }
    if (!contactFormState.contactLastName) {
      isValid = false;
      setShowContactLastNameErrorMessage({
        show: true,
        message: "Please type your last name!"
      })
    }
    if (!contactFormState.contactEmail) {
      isValid = false;
      setShowContactEmailErrorMessage({
        show: true,
        message: "Please type your email address!"
      })
    }
    if (!contactFormState.contactPhone) {
      isValid = false;
      setShowContactPhoneErrorMessage({
        show: true,
        message: "Please type your phone number!"
      })
    }
    if (!contactFormState.contactMessage) {
      isValid = false;
      setShowContactMessageErrorMessage({
        show: true,
        message: "Please type your message!"
      })
    }

    return isValid
  };

  const handleContactUsFormChange = e => {
    let value = e.target.value;
    setContactFormValues({
      ...contactFormState,
      [e.target.name]: value,
    });
  };

  const saveContactsToMailchimp = async () => {
    try {
      await addToMailchimp(contactFormState.contactEmail, {
        FNAME: contactFormState.contactFirstName,
        LNAME: contactFormState.contactLastName,
        PHONE: contactFormState.contactPhone,
        MESSAGE: contactFormState.contactMessage,
        }, submitContactUsUrl
      );
    } catch (err) {
      console.error(err);
    }
    return true;
  };

  const validateAndSaveContactFormData = async () => {
    if (validateContactForm()) {
      await saveContactsToMailchimp();
    }
  }

  const holidayCarousel = useRef(null);

  const updateCarouselImage = (imageName) => {
    const index = data.holidaysCarousel.edges.findIndex(edge => edge.node.name === imageName)
    if (holidayCarousel.current) {
      holidayCarousel.current.goToSlide(index);
    }
  }

  const selectHolidayStyleFromCarousel = (imageName) => {
    const holidayStyle = holidayStyles.find(item => item.images.indexOf(imageName) > -1)
    handleRegisterForPhotosFormChange({
      target: {
        name: 'holidayStyle',
        value: holidayStyle.title
      }
    })
    changeHolidaySlide(holidayStyle)
  }

  const classicCarousel = useRef(null);

  const updateClassicCarouselImage = (imageName) => {
    const index = data.classicCarousel.edges.findIndex(edge => edge.node.name === imageName)
    if (classicCarousel.current) {
      classicCarousel.current.goToSlide(index);
    }
  }

  const selectClassicStyleFromCarousel = (imageName) => {
    const classicStyle = classicBackdrops.find(item => item.images.indexOf(imageName) > -1)
    handleRegisterForPhotosFormChange({
      target: {
        name: 'classicStyle',
        value: classicStyle.title
      }
    })
    changeClassicSlide(classicStyle)
  }

  const isHolidayStyleSelected = (imageName) => {
    const holidayStyle = holidayStyles.find(item => item.images.indexOf(imageName) > -1)
    return holidayStyle.title === formState.holidayStyle;
  }

  const isClassicStyleSelected = (imageName) => {
    const classicStyle = classicBackdrops.find(item => item.images.indexOf(imageName) > -1)
    return classicStyle.title === formState.classicBackdropStyle;
  }

  return (
    <Layout>
      <Container fluid className={babyPortraitsStyle.portraitStyle}>
        <Row>
          <Col className={babyPortraitsStyle.largeTitle}>
            <h1 className={globalStyle.entryTitle}>BABY AND TODDLER <span>PORTRAITS</span></h1>
          </Col>
        </Row>

        <Row className={"justify-content-center " + babyPortraitsStyle.content}>
          <Col xs={12} sm={12} md={11} lg={10} xl={9}>
            <Row>
              <Col xs={6}>
                <Row>
                  <Col>
                    { formState.portraitType === 'Holiday' && data.holidaysCarousel.edges.map(edge => {
                      return (
                        <div key={edge.node.name} className={selectedHolidayStyleImg === edge.node.name ? '' : 'd-none'}>
                          <HolidaysPortraitCarousel
                            photo={edge.node.childImageSharp.fluid}
                          >
                          </HolidaysPortraitCarousel>
                        </div>
                      )
                    })}

                    { formState.portraitType === 'Classic' && data.classicCarousel.edges.map(edge => (
                      <div key={edge.node.name} className={selectedClassicBackdropImg === edge.node.name ? '' : 'd-none'}>
                        <ClassicPortraitCarousel
                          photo={edge.node.childImageSharp.fluid}
                        >
                        </ClassicPortraitCarousel>
                      </div>
                    ))}
                  </Col>
                </Row>
                <Row style={{"paddingLeft": "0px"}}>
                  { formState.portraitType === 'Holiday' &&
                    <Col>
                      <Carousel
                        ref={holidayCarousel}
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        autoPlay={false}
                        centerMode={false}
                        className={babyPortraitsStyle.smallCarousel}
                        containerClass="container-with-dots"
                        dotListClass=""
                        focusOnSelect={false}
                        itemClass=""
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                          desktop: {
                            breakpoint: {
                              max: 3000,
                              min: 1024
                            },
                            items: 4,
                            partialVisibilityGutter: 40
                          },
                          mobile: {
                            breakpoint: {
                              max: 464,
                              min: 0
                            },
                            items: 2,
                            partialVisibilityGutter: 30
                          },
                          tablet: {
                            breakpoint: {
                              max: 1024,
                              min: 464
                            },
                            items: 2,
                            partialVisibilityGutter: 30
                          }
                        }}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                        infinite={false}
                      >
                        {data.holidaysCarousel.edges.map((edge, item) => (
                          <div
                            key={edge.node.name}
                            onClick={() => selectHolidayStyleFromCarousel(edge.node.name)}
                            className={isHolidayStyleSelected(edge.node.name) ? 'selected' : 'notSelected'}
                          ><Img fluid={edge.node.childImageSharp.fluid} /></div>
                        ))}
                      </Carousel>
                    </Col>
                  }

                  { formState.portraitType === 'Classic' &&
                  <Col>
                    <Carousel
                      ref={classicCarousel}
                      additionalTransfrom={0}
                      arrows
                      autoPlaySpeed={3000}
                      autoPlay={false}
                      centerMode={false}
                      className={babyPortraitsStyle.smallCarousel}
                      containerClass="container-with-dots"
                      dotListClass=""
                      focusOnSelect={false}
                      itemClass=""
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024
                          },
                          items: 4,
                          partialVisibilityGutter: 40
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0
                          },
                          items: 2,
                          partialVisibilityGutter: 30
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464
                          },
                          items: 2,
                          partialVisibilityGutter: 30
                        }
                      }}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                      infinite={false}
                    >
                      {data.classicCarousel.edges.map((edge, item) => (
                        <div
                          key={edge.node.name}
                          onClick={() => selectClassicStyleFromCarousel(edge.node.name)}
                          className={isClassicStyleSelected(edge.node.name) ? 'selected' : 'notSelected'}
                        ><Img fluid={edge.node.childImageSharp.fluid} /></div>
                      ))}
                    </Carousel>
                  </Col>
                  }

                </Row>
                <Row>
                  <Col>
                    <p>Let us turn your photo into a beautiful holiday or classic portrait.</p>
                    <p>Upload your picture and our photo professionals will transform it.</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <Row>
                  <Col>
                    <p className={babyPortraitsStyle.choosePortretType}>Choose Holiday or Classic</p>

                    <button className={[formState.portraitType === 'Holiday' ? babyPortraitsStyle.active : '', babyPortraitsStyle.themeButton].join(' ')} onClick={chooseHoliday}>Holiday</button>
                    <button className={[formState.portraitType === 'Classic' ? babyPortraitsStyle.active : '', babyPortraitsStyle.themeButton].join(' ')} onClick={chooseClassic}>Classic</button>

                    <p className={babyPortraitsStyle.sectionTitle}>Choose your theme</p>
                    {formState.portraitType === "Holiday" && (
                      <Form.Row className={[babyPortraitsStyle.rowStyle, babyPortraitsStyle.backdropChoicesRow].join(" ")}>
                        {
                          holidayStyles.map(holidayStyle => (
                            <Col key={holidayStyle.id}
                                 className={[formState.holidayStyle === holidayStyle.title ? babyPortraitsStyle.active : '', babyPortraitsStyle.themeButton, babyPortraitsStyle.holidayButton].join(' ')}
                                 // onClick={(e) => {handleRegisterForPhotosFormChange(e); changeHolidaySlide(holidayStyle)}}
                            >
                              <Form.Check
                                className={[babyPortraitsStyle.backdropRadiobox, babyPortraitsStyle.styleRadiobox, babyPortraitsStyle.holidayStyleRadiobox]}>
                                <Form.Check.Input type='radio'
                                                  id={holidayStyle.id}
                                                  name="holidayStyle"
                                                  value={holidayStyle.title}
                                                  checked={formState.holidayStyle === holidayStyle.title}
                                                  onChange={(e) => {handleRegisterForPhotosFormChange(e); changeHolidaySlide(holidayStyle)}}/>
                                <Form.Check.Label htmlFor={holidayStyle.id}
                                                  className={babyPortraitsStyle.holidayLabel}
                                                  onClick={(e) => {handleRegisterForPhotosFormChange(e); changeHolidaySlide(holidayStyle)}}
                                >{holidayStyle.title}
                                </Form.Check.Label>
                              </Form.Check>
                            </Col>))
                        }
                      </Form.Row>
                    )}
                    {formState.portraitType === "Classic" && (
                      <Form.Row className={[babyPortraitsStyle.rowStyle, babyPortraitsStyle.backdropChoicesRow, "justify-content-md-center"].join(" ")}>
                        {classicBackdrops.map(classicBackdrop => (
                          <Col key={classicBackdrop.id}>
                            <Form.Check className={[babyPortraitsStyle.backdropRadiobox, babyPortraitsStyle.styleRadiobox]}>
                              <Form.Check.Input type='radio' id={classicBackdrop.id} name="classicBackdropStyle" value={classicBackdrop.id}
                                                className={babyPortraitsStyle.classicBackdropStyle}
                                                checked={formState.classicBackdropStyle === classicBackdrop.id}
                                                onChange={(e) => {handleRegisterForPhotosFormChange(e); changeClassicSlide(classicBackdrop)}}/>
                              <Form.Check.Label htmlFor={classicBackdrop.id}
                                                onClick={(e) => {handleRegisterForPhotosFormChange(e); changeClassicSlide(classicBackdrop)}}>
                                <div className={[formState.classicBackdropStyle === classicBackdrop.id ? babyPortraitsStyle.colorSample : babyPortraitsStyle.colorSampleUnselected, classicBackdrop.cssClass].join(" ")}></div>
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        ))}
                      </Form.Row>
                    )}

                    <Form.Row>
                      <Col xs={12} md={12} sm={12}>
                        {showPortretTypeErrorMessage.show && (
                          <Alert
                            variant="danger"
                            onClose={() => setShowPortretTypeErrorMessage({ show: false })}
                            dismissible
                          >
                            {showPortretTypeErrorMessage.message}
                          </Alert>
                        )}
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col className={babyPortraitsStyle.sectionTitle}>Choose your delivery</Col>
                      <Col className={babyPortraitsStyle.redirectToDetails}><a href="#deliveryGuide">Delivery guide</a></Col>
                    </Form.Row>

                    <Form.Row>
                      <Form.Check>
                        <Form.Check.Input type='radio' id="digital-delivery-only"
                                          name="deliveryType"
                                          value="Digital Delivery Only"
                                          checked={formState.deliveryType === "Digital Delivery Only"}
                                          onChange={chooseDigitalDeliveryOnly}/>
                        <Form.Check.Label htmlFor="digital-delivery-only">Digital Delivery</Form.Check.Label>
                      </Form.Check>
                    </Form.Row>

                    <Form.Row>
                      <Form.Check>
                        <Form.Check.Input type='radio' id="digital-delivery-and-print-and-ship"
                                          name="deliveryType"
                                          value="Digital Delivery and Print and Ship a 5x7 and 8x10"
                                          checked={formState.deliveryType === "Digital Delivery and Print and Ship a 5x7 and 8x10"}
                                          onClick={() => setShowDeliveryTypeErrorMessage({ show: false })}
                                          onChange={handleRegisterForPhotosFormChange}/>
                        <Form.Check.Label htmlFor="digital-delivery-and-print-and-ship">
                          Digital Delivery + Printed 5x7 and Printed 8x10
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Row>

                    <Form.Row>
                      <Form.Check>
                        <Form.Check.Input type='radio' id="digital-delivery-and-print-frame-and-ship-art"
                                          name="deliveryType"
                                          value="Digital Delivery and Print, Frame and Ship Finished Art"
                                          checked={formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art"}
                                          onClick={() => setShowDeliveryTypeErrorMessage({show: false})}
                                          onChange={handleRegisterForPhotosFormChange}/>
                        <Form.Check.Label htmlFor="digital-delivery-and-print-frame-and-ship-art">
                          Digital Delivery + Printed 5x7 and Printed 8x10 + Matted and framed 8x10
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Row>
                  </Col>
                </Row>

                {formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" &&
                  <p className={[babyPortraitsStyle.sectionTitle, babyPortraitsStyle.frameTitle].join(' ')}>Select your frame
                  color</p>
                }
                { formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" &&
                  <Form.Row className={babyPortraitsStyle.rowStyle}>
                    <Col xs={12} md={2} sm={2}>
                      <Form.Check className={[babyPortraitsStyle.backdropRadiobox, babyPortraitsStyle.styleRadiobox]}>
                        <Form.Check.Input type='radio' id="black" name="frameColor" value="Black"
                                          className={babyPortraitsStyle.frameColor}
                                          onChange={handleRegisterForPhotosFormChange}/>
                        {formState.frameColor === "Black" &&
                        <Form.Check.Label htmlFor="black">
                          <div className={[babyPortraitsStyle.colorSample, babyPortraitsStyle.midnightColor].join(" ")}
                               onClick={handleRegisterForPhotosFormChange}></div>
                        </Form.Check.Label>
                        }
                        {formState.frameColor !== "Black" &&
                        <Form.Check.Label htmlFor="black">
                          <div className={[babyPortraitsStyle.colorSampleUnselected, babyPortraitsStyle.midnightColor].join(" ")}
                               onClick={handleRegisterForPhotosFormChange}></div>
                        </Form.Check.Label>
                        }
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={2} sm={2}>
                      <Form.Check className={[babyPortraitsStyle.backdropRadiobox, babyPortraitsStyle.styleRadiobox]}>
                        <Form.Check.Input type='radio' id="white" name="frameColor" value="White"
                                          className={babyPortraitsStyle.frameColor}
                                          onChange={handleRegisterForPhotosFormChange}/>
                        <Form.Check.Label htmlFor="white">
                          {formState.frameColor === "White" &&
                          <Form.Check.Label htmlFor="white">
                            <div className={[babyPortraitsStyle.colorSample, babyPortraitsStyle.whiteColor].join(" ")}
                                 onClick={handleRegisterForPhotosFormChange}></div>
                          </Form.Check.Label>
                          }
                          {formState.frameColor !== "White" &&
                          <Form.Check.Label htmlFor="white">
                            <div className={[babyPortraitsStyle.colorSampleUnselected, babyPortraitsStyle.whiteColor].join(" ")}
                                 onClick={handleRegisterForPhotosFormChange}></div>
                          </Form.Check.Label>
                          }
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={2} sm={2}>
                      <Form.Check className={[babyPortraitsStyle.backdropRadiobox, babyPortraitsStyle.styleRadiobox]}>
                        <Form.Check.Input type='radio' id="grey" name="frameColor" value="Grey"
                                          className={babyPortraitsStyle.frameColor}
                                          onChange={handleRegisterForPhotosFormChange}/>
                        <Form.Check.Label htmlFor="grey">
                          {formState.frameColor === "Grey" &&
                          <Form.Check.Label htmlFor="grey">
                            <div className={[babyPortraitsStyle.colorSample, babyPortraitsStyle.greyColor].join(" ")}
                                 onClick={handleRegisterForPhotosFormChange}></div>
                          </Form.Check.Label>
                          }
                          {formState.frameColor !== "Grey" &&
                          <Form.Check.Label htmlFor="grey">
                            <div className={[babyPortraitsStyle.colorSampleUnselected, babyPortraitsStyle.greyColor].join(" ")}
                                 onClick={handleRegisterForPhotosFormChange}></div>
                          </Form.Check.Label>
                          }
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={2} sm={2}>
                      <Form.Check className={[babyPortraitsStyle.backdropRadiobox, babyPortraitsStyle.styleRadiobox]}>
                        <Form.Check.Input type='radio' id="blue" name="frameColor" value="Blue"
                                          className={babyPortraitsStyle.frameColor}
                                          onChange={handleRegisterForPhotosFormChange}/>
                        <Form.Check.Label htmlFor="blue">
                          {formState.frameColor === "Blue" &&
                          <Form.Check.Label htmlFor="blue">
                            <div className={[babyPortraitsStyle.colorSample, babyPortraitsStyle.blueColor].join(" ")}
                                 onClick={handleRegisterForPhotosFormChange}></div>
                          </Form.Check.Label>
                          }
                          {formState.frameColor !== "Blue" &&
                          <Form.Check.Label htmlFor="blue">
                            <div className={[babyPortraitsStyle.colorSampleUnselected, babyPortraitsStyle.blueColor].join(" ")}
                                 onClick={handleRegisterForPhotosFormChange}></div>
                          </Form.Check.Label>
                          }
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Form.Row>
                }

                <Form.Row>
                  <Col className={[babyPortraitsStyle.sectionTitle, babyPortraitsStyle.frameTitle].join(' ')}>Upload your photo</Col>
                  <Col className={babyPortraitsStyle.redirectToDetails}><a href="#image-guidelines">Photo guide</a></Col>
                </Form.Row>

                <Form.Row className={[babyPortraitsStyle.rowStyle, "justify-content-md-left"].join(" ")}>
                  <Col xs={12} md={4} sm={6}>
                    <Form.Group className={babyPortraitsStyle.formGroup}>
                      <Form.File
                        id="custom-file-translate-scss"
                        label={"Choose image"}
                        lang="en"
                        custom
                        className={babyPortraitsStyle.uploadButton}
                        accept="image/jpeg,image/jpg,image/png"
                        name="photo"
                        onClick={() => setShowPhotoErrorMessage({ show: false })}
                        onChange={handleRegisterForPhotosFormChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>{formState.photoName}</Col>
                </Form.Row>

                {showPhotoErrorMessage.show &&
                <Form.Row className="justify-content-md-left">
                  <Col xs={12} md={8} sm={10}>
                    <Alert
                      variant="danger"
                      onClose={() => setShowPhotoErrorMessage({ show: false })}
                      dismissible
                    >
                      {showPhotoErrorMessage.message}
                    </Alert>
                  </Col>
                </Form.Row>
                }

                <Form.Row>
                  {formState.deliveryType === "Digital Delivery Only" &&
                  <Col className={babyPortraitsStyle.priceContainer}>
                    <span className={babyPortraitsStyle.price}>${defaultPrices.digitalDelivery} USD</span>
                  </Col>
                  }

                  {formState.deliveryType === "Digital Delivery and Print and Ship a 5x7 and 8x10" &&
                  <Col className={babyPortraitsStyle.priceContainer}>
                    <span className={babyPortraitsStyle.price}>${defaultPrices.digitalDeliveryAndPrint} USD</span>
                  </Col>
                  }

                  {formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" &&
                  <Col className={babyPortraitsStyle.priceContainer}>
                    <span className={babyPortraitsStyle.price}>${defaultPrices.digitalDeliveryAndPrintAndFrame} USD</span>
                  </Col>
                  }

                  <Col className={babyPortraitsStyle.redirectToDetails}><a href="#ourProcess">How it works</a></Col>
                </Form.Row>

                <Form.Row className={[babyPortraitsStyle.rowStyle, "justify-content-md-left"].join(" ")}>
                  <Col xs={12} md={4} sm={6}>
                    <Button className={babyPortraitsStyle.addToCartButton}
                            // onClick={() => {setModalShow(true); validateAndSaveData()}}>Add to Cart
                            onClick={handleShowModal}>Add to Cart
                      {/*onClick={(e) => {handleRegisterForPhotosFormChange(e); changeHolidaySlide(holidayStyle)}}*/}
                    </Button>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col>
                    <Modal
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={modalShow}
                      onHide={handleCloseModal}
                      className="addBabyPortraitDetailsModal"
                    >
                      <Modal.Header className={[babyPortraitsStyle.modalBackground, babyPortraitsStyle.modalHeader].join(' ')}
                                    closeButton>
                      </Modal.Header>
                      <Modal.Body className={babyPortraitsStyle.modalBackground}>
                        <Form.Row className={[babyPortraitsStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                          <Col xs={12} sm={10} md={8}>
                            <Form.Group as={Col} controlId="formName" className={[babyPortraitsStyle.registerFormGroup, babyPortraitsStyle.formLabel].join(' ')}>
                              <Form.Label className={[formStyle.formLabel, formStyle.required]}>Your name</Form.Label>
                              <Form.Control
                                size="sm"
                                value={formState.name}
                                name="name"
                                type="text"
                                placeholder="Type your name"
                                onClick={() => setShowNameErrorMessage({ show: false })}
                                onChange={handleRegisterForPhotosFormChange}
                                className={babyPortraitsStyle.formInput}
                              />
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        {showNameErrorMessage.show &&
                        <Form.Row className="justify-content-md-center">
                          <Col xs={12} md={8} sm={10}>
                            <Alert
                              variant="danger"
                              onClose={() => setShowNameErrorMessage({show: false})}
                              dismissible
                            >
                              {showNameErrorMessage.message}
                            </Alert>
                          </Col>
                        </Form.Row>
                        }

                        <Form.Row className={[babyPortraitsStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                          <Col xs={10} sm={10} md={8}>
                            <Form.Group as={Col} controlId="babyName" name="babyName"
                                        className={[babyPortraitsStyle.registerFormGroup, babyPortraitsStyle.formLabel].join(' ')}>
                              <Form.Label className={[formStyle.formLabel, formStyle.required]}>Your child's baby name</Form.Label>
                              <Form.Control
                                className={babyPortraitsStyle.formInput}
                                size="sm"
                                value={formState.babyName}
                                type="text"
                                name="babyName"
                                placeholder="Type baby name"
                                onChange={handleRegisterForPhotosFormChange}
                                onClick={() => setShowBabyNameErrorMessage({ show: false })}
                              />
                            </Form.Group>
                          </Col>
                        </Form.Row>

                        {showBabyNameErrorMessage.show &&
                        <Form.Row className="justify-content-md-center">
                          <Col xs={12} sm={10} md={8} >
                            <Alert
                              variant="danger"
                              onClose={() => setShowBabyNameErrorMessage({show: false})}
                              dismissible
                            >
                              {showBabyNameErrorMessage.message}
                            </Alert>
                          </Col>
                        </Form.Row>
                        }

                        <Form.Row className={[babyPortraitsStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                          <Col xs={12} sm={10} md={8} >
                            <Form.Group as={Col} controlId="formEmail" className={[babyPortraitsStyle.registerFormGroup, babyPortraitsStyle.formLabel]}>
                              <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email address</Form.Label>
                              <Form.Control
                                size="sm"
                                value={formState.email}
                                name="email"
                                type="email"
                                placeholder="Type your email address"
                                onClick={() => setShowEmailErrorMessage({ show: false })}
                                onChange={handleRegisterForPhotosFormChange}
                                className={babyPortraitsStyle.formInput}
                              />
                            </Form.Group>
                          </Col>
                        </Form.Row>

                        {showEmailErrorMessage.show &&
                        <Form.Row className="justify-content-md-center">
                          <Col xs={12} sm={10} md={8} >

                            <Alert
                              variant="danger"
                              onClose={() => setShowEmailErrorMessage({ show: false })}
                              dismissible
                            >
                              {showEmailErrorMessage.message}
                            </Alert>
                          </Col>
                        </Form.Row>
                        }

                        <Form.Row className={[babyPortraitsStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                          <Col xs={12} sm={10} md={8} >
                            <Form.Group as={Col} controlId="formAddress" className={[babyPortraitsStyle.registerFormGroup, babyPortraitsStyle.formLabel]}>
                              <Form.Label className={[formStyle.formLabel, formStyle.required]}>Mailing address</Form.Label>
                              <Form.Control
                                size="sm"
                                value={formState.address}
                                name="address"
                                type="text"
                                placeholder="Type your address"
                                onClick={() => setShowAddressErrorMessage({ show: false })}
                                onChange={handleRegisterForPhotosFormChange}
                                className={babyPortraitsStyle.formInput}
                              />
                            </Form.Group>
                          </Col>
                        </Form.Row>

                        {showAddressErrorMessage.show &&
                        <Form.Row className="justify-content-md-center">
                          <Col xs={12} sm={10} md={8} >

                            <Alert
                              variant="danger"
                              onClose={() => setShowAddressErrorMessage({ show: false })}
                              dismissible
                            >
                              {showAddressErrorMessage.message}
                            </Alert>
                          </Col>
                        </Form.Row>
                        }

                        <Form.Row className={[babyPortraitsStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                          <Col xs={12} sm={10} md={8} >
                            <Form.Group as={Col} controlId="formPhone" className={[babyPortraitsStyle.registerFormGroup, babyPortraitsStyle.formLabel]}>
                              <Form.Label className={[formStyle.formLabel, formStyle.required]}>Phone no</Form.Label>
                              <Form.Control
                                size="sm"
                                value={formState.phone}
                                name="phone"
                                type="text"
                                placeholder="Type your phone no."
                                onClick={() => setShowPhoneErrorMessage({ show: false })}
                                onChange={handleRegisterForPhotosFormChange}
                                className={babyPortraitsStyle.formInput}
                              />
                            </Form.Group>
                          </Col>
                        </Form.Row>

                        {showPhoneErrorMessage.show &&
                        <Form.Row className="justify-content-md-center">
                          <Col xs={12} sm={10} md={8} >

                            <Alert
                              variant="danger"
                              onClose={() => setShowPhoneErrorMessage({ show: false })}
                              dismissible
                            >
                              {showPhoneErrorMessage.message}
                            </Alert>
                          </Col>
                        </Form.Row>
                        }

                        <Row className="justify-content-md-center">
                          <Col className="text-center">
                            <Button
                              type="button"
                              className={[
                                globalStyle.btnPrimary,
                                babyPortraitsStyle.confirmButton
                              ]}
                              onClick={validateAndSaveData}
                            >
                              {/*BUY NOW {finalPrice ? ' $' + finalPrice : ''}*/}
                              Confirm
                            </Button>
                          </Col>
                        </Row>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Form.Row>
              </Col>
            </Row>

            <Row className={babyPortraitsStyle.subTitle}>
              <Col>
                <h5 id="ourProcess">How It Works</h5>
              </Col>
            </Row>

            <Row className={babyPortraitsStyle.subTitle}>
              <Col xs={12} sm={4} md={4}>
                <p className={babyPortraitsStyle.stepNumber}>1</p>
                <div className={babyPortraitsStyle.sectionContent}>
                  <span className={babyPortraitsStyle.sectionTitle}>Choose Your Theme</span>
                </div>
                <div>
                  <i className={[babyPortraitsStyle.rightArrow, "fa fa-arrow-circle-right"].join(' ')} aria-hidden="true"></i>
                </div>
                <div style={{clear: "both"}}></div>
                <hr className={babyPortraitsStyle.line} />
                <span>Choose between holiday and classic backdrops. Each classic backdrop includes the name of your child.</span>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <p className={babyPortraitsStyle.stepNumber}>2</p>
                <div className={babyPortraitsStyle.sectionContent}>
                  <span className={babyPortraitsStyle.sectionTitle}>Choose Your Delivery</span>
                </div>
                <div>
                  <i className={[babyPortraitsStyle.rightArrow, "fa fa-arrow-circle-right"].join(' ')} aria-hidden="true"></i>
                </div>
                <div style={{clear: "both"}}></div>
                <hr className={babyPortraitsStyle.line} />
                <span>Choose between digital portraits, printed, and framed options.</span>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <p className={babyPortraitsStyle.stepNumber}>3</p>
                <div>
                  <span className={babyPortraitsStyle.sectionTitle}>Upload Your Picture</span>
                </div>
                <div style={{clear: "both"}}></div>
                <hr className={babyPortraitsStyle.line} />
                <span>Upload any picture of your cutie. Please follow our image guidelines.</span>
                <p className={babyPortraitsStyle.imageGuidelinesContent}><a href="#imageGuidelinesContent">See Image Guidelines</a></p>
              </Col>
            </Row>

            <Row className={babyPortraitsStyle.subTitle} id="image-guidelines">
              <Col id="imageGuidelinesContent">
                <h5 id="image-guidelines">Image Guidelines:</h5>
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col xs={12} sm={6} md={6} >
                <ul className={babyPortraitsStyle.messageStyle}>
                  <li>Use a smartphone or high-quality camera.</li>
                  <li>Take a photo or choose an existing photo taken during daylight hours or in a bright space.</li>
                  <li>Ensure the whole face and neck is visible and there are no shadows.</li>
                  <li>Upload your favorite look and let us get to work for you!</li>
                  Not sure whether your image will work? <a className={babyPortraitsStyle.redirectLink} href={"mailto:aimeecruz@momsonmaternity.com"}>Email us</a> and we will let you know!
                </ul>
              </Col>

              <Col xs={12} sm={6} md={6}>
                <video width="100%" height="auto" autoPlay loop muted playsInline>
                  <source src={data.portraitVideo.publicURL} type="video/mp4"/>
                </video>
              </Col>

              <div className={babyPortraitsStyle.lineContent}>
                <hr className={babyPortraitsStyle.imageGuidelinesHr} />
              </div>
            </Row>

            <Row className={babyPortraitsStyle.subTitle}>
              <Col>
                <h5 id="deliveryGuide">Delivery Guide:</h5>
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col xs={12} sm={6} md={6} >
                <Img fluid={data.deliveryPhoto.childImageSharp.fluid}/>
              </Col>

              <Col xs={12} sm={6} md={6}>
                <ul className={[babyPortraitsStyle.messageStyle, babyPortraitsStyle.deliveryMessageStyle].join(' ')}>
                  <li>Digital Delivery:</li>
                  <p>Digital delivery within 48 hours.</p>
                  <li>Digital Delivery + Printed 5x7 and Printed 8x10:</li>
                  <p>Digital delivery within 48 hours. Prints arrive 5-7 days from digital delivery date.</p>
                  <li>Digital Delivery + Printed 5x7 and Printed 8x10 + Matted and Framed 8x10:</li>
                  <p>Digital delivery within 48 hours. Framed art and prints arrive within 7 days from digital delivery date.</p>
                </ul>
              </Col>

              <div className={babyPortraitsStyle.lineContent}>
                <hr className={babyPortraitsStyle.imageContactHr} />
              </div>

            </Row>

            {/*Contact form*/}
            <Row className={babyPortraitsStyle.subTitle}>
              <Col>
                <h5>Contact us:</h5>
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col xs={12} sm={6} md={6}>
                <Form onSubmit={handleContactUsFormChange}>
                <Form.Row className={[babyPortraitsStyle.rowStyle, "justify-content-md-left"].join(" ")}>
                  <Col xs={12} sm={6} md={6}>
                    <Form.Group as={Col} controlId="contactFirstName" name="contactFirstName"
                                className={[babyPortraitsStyle.registerFormGroup, babyPortraitsStyle.formLabel].join(' ')}>
                      <Form.Label className={[formStyle.formLabel, formStyle.required]}>First name</Form.Label>
                      <Form.Control
                        className={babyPortraitsStyle.formInput}
                        size="sm"
                        value={contactFormState.contactFirstName}
                        type="text"
                        name="contactFirstName"
                        placeholder="Type your first name"
                        onChange={handleContactUsFormChange}
                        onClick={() => setShowContactFirstNameErrorMessage({ show: false })}
                      />
                    </Form.Group>

                    { showContactFirstNameErrorMessage.show &&
                      <Form.Row className="justify-content-md-left">
                        <Col xs={12} sm={12} md={12} >

                          <Alert
                            variant="danger"
                            onClose={() => setShowContactFirstNameErrorMessage({ show: false })}
                            dismissible
                          >
                            {showContactFirstNameErrorMessage.message}
                          </Alert>
                        </Col>
                      </Form.Row>
                    }
                  </Col>

                  <Col xs={12} sm={6} md={6}>
                    <Form.Group as={Col} controlId="contactLastName" name="contactLastName"
                                className={[babyPortraitsStyle.registerFormGroup, babyPortraitsStyle.formLabel].join(' ')}>
                      <Form.Label className={[formStyle.formLabel, formStyle.required]}>Last name</Form.Label>
                      <Form.Control
                        className={babyPortraitsStyle.formInput}
                        size="sm"
                        value={contactFormState.contactLastName}
                        type="text"
                        name="contactLastName"
                        placeholder="Type your last name"
                        onChange={handleContactUsFormChange}
                        onClick={() => setShowContactLastNameErrorMessage({ show: false })}
                      />
                    </Form.Group>

                    { showContactLastNameErrorMessage.show &&
                    <Form.Row className="justify-content-md-left">
                      <Col xs={12} sm={12} md={12} >

                        <Alert
                          variant="danger"
                          onClose={() => setShowContactLastNameErrorMessage({ show: false })}
                          dismissible
                        >
                          {showContactLastNameErrorMessage.message}
                        </Alert>
                      </Col>
                    </Form.Row>
                    }
                  </Col>
                </Form.Row>

                <Form.Row className={[babyPortraitsStyle.rowStyle, "justify-content-md-left"].join(" ")}>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Group as={Col} controlId="contactEmail" name="contactEmail"
                                className={[babyPortraitsStyle.registerFormGroup, babyPortraitsStyle.formLabel].join(' ')}>
                      <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email address</Form.Label>
                      <Form.Control
                        className={babyPortraitsStyle.formInput}
                        size="sm"
                        value={contactFormState.contactEmail}
                        type="email"
                        name="contactEmail"
                        placeholder="Type your email"
                        onChange={handleContactUsFormChange}
                        onClick={() => setShowContactEmailErrorMessage({ show: false })}
                      />
                    </Form.Group>

                    { showContactEmailErrorMessage.show &&
                    <Form.Row className="justify-content-md-left">
                      <Col xs={12} sm={12} md={12} >

                        <Alert
                          variant="danger"
                          onClose={() => setShowContactEmailErrorMessage({ show: false })}
                          dismissible
                        >
                          {showContactEmailErrorMessage.message}
                        </Alert>
                      </Col>
                    </Form.Row>
                    }
                  </Col>
                </Form.Row>

                <Form.Row className={[babyPortraitsStyle.rowStyle, "justify-content-md-left"].join(" ")}>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Group as={Col} controlId="contactPhone" name="contactPhone"
                                className={[babyPortraitsStyle.registerFormGroup, babyPortraitsStyle.formLabel].join(' ')}>
                      <Form.Label className={[formStyle.formLabel, formStyle.required]}>Phone Number</Form.Label>
                      <Form.Control
                        className={babyPortraitsStyle.formInput}
                        size="sm"
                        value={contactFormState.contactPhone}
                        type="text"
                        name="contactPhone"
                        placeholder="Type your phone number"
                        onChange={handleContactUsFormChange}
                        onClick={() => setShowContactPhoneErrorMessage({ show: false })}
                      />
                    </Form.Group>

                    { showContactPhoneErrorMessage.show &&
                    <Form.Row className="justify-content-md-left">
                      <Col xs={12} sm={12} md={12} >

                        <Alert
                          variant="danger"
                          onClose={() => setShowContactPhoneErrorMessage({ show: false })}
                          dismissible
                        >
                          {showContactPhoneErrorMessage.message}
                        </Alert>
                      </Col>
                    </Form.Row>
                    }
                  </Col>
                </Form.Row>

                <Form.Row className={[babyPortraitsStyle.rowStyle, "justify-content-md-left"].join(" ")}>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Group as={Col} controlId="contactMessage" name="contactMessage"
                                className={[babyPortraitsStyle.registerFormGroup, babyPortraitsStyle.formLabel].join(' ')}>
                      <Form.Label className={[formStyle.formLabel, formStyle.required]}>Message</Form.Label>
                      <Form.Control
                        className={babyPortraitsStyle.formInput}
                        size="sm"
                        value={contactFormState.contactMessage}
                        type="text"
                        name="contactMessage"
                        placeholder="Type your question here..."
                        onChange={handleContactUsFormChange}
                        onClick={() => setShowContactMessageErrorMessage({ show: false })}
                      />
                    </Form.Group>

                    { showContactMessageErrorMessage.show &&
                    <Form.Row className="justify-content-md-left">
                      <Col xs={12} sm={12} md={12} >

                        <Alert
                          variant="danger"
                          onClose={() => setShowContactMessageErrorMessage({ show: false })}
                          dismissible
                        >
                          {showContactMessageErrorMessage.message}
                        </Alert>
                      </Col>
                    </Form.Row>
                    }
                  </Col>
                </Form.Row>

                <Button type="button"
                        className={babyPortraitsStyle.submitButton}
                        onClick={validateAndSaveContactFormData}>
                  Submit
                </Button>
                </Form>
              </Col>

              <Col xs={12} sm={6} md={6} >
                <Img fluid={data.contactPhoto.childImageSharp.fluid}/>
              </Col>
            </Row>
          </Col>
        </Row>


      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    portraitPhotos: allFile(filter: {relativePath: {regex: "/portrait-photos/portraits\/.*/"}}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    classicPortrait: file(relativePath: { eq: "portrait-photos/portraits-white/classic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    classicFullView: file(relativePath: { eq: "portrait-photos/frames/Lucas-regular-full-view.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    holidayPortrait: file(relativePath: { eq: "portrait-photos/portraits-white/holiday.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    holidayFullView: file(relativePath: { eq: "portrait-photos/frames/Olivia-holiday-full-view.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nurseryPortrait: file(relativePath: { eq: "portrait-photos/portraits-white/nursery.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nurseryFullView: file(relativePath: { eq: "portrait-photos/frames/Olivia-nursery-full-view.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    portraitVideo: file(
      relativePath: {eq: "portrait-photos/videos/portrait-video.mp4"}
    ) {
        publicURL
        name
    }
    babyPortraitCarousel: allFile(filter: {relativePath: {regex: "/portrait-photos/frames-process\/.*/"}}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    holidaysCarousel: allFile(filter: {relativePath: {regex: "/portrait-photos/holidays\/.*/"}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    classicCarousel: allFile(filter: {relativePath: {regex: "/portrait-photos/classic\/.*/"}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    deliveryPhoto: file(relativePath: { eq: "portrait-photos/graphics/delivery.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contactPhoto: file(relativePath: { eq: "portrait-photos/graphics/contact.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skus: allStripeSku {
      edges {
        node {
          id
          price
          attributes {
            size
          }
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export default BabyPortraitsPage
